import React from 'react'
import PricingMedior from '../Components/Pricing/PricingMedior'


export default function PricingPage() {
    return (
        <>
          <PricingMedior/>
        </>
    )
}
