import React from 'react'
import Error from '../Components/Error/Error'
import Form from '../Components/Form/Forms'
import FormSignup from '../Components/Form/FormSignup'


export default function Login() {
    return (
        <>
            <Form/>
        </>
    )
}
