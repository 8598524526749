import React from 'react'
import PricingSenior from '../Components/Pricing/PricingSenior'


export default function PricingPage() {
    return (
        <>
          <PricingSenior/>
        </>
    )
}