import React from 'react'
import Princing from '../Components/Pricing/Princing'


export default function PricingPage() {
    return (
        <>
          <Princing/>
        </>
    )
}
